import React from "react";
import Loader from "./Loader";

const Header = () => {
	return (
		<>
			<Loader />
			<header className="fixed-top header-fullpage bordered-nav wow fadeInDown">
				<div className="top-bar-right d-flex align-items-center text-md-left">
					<div className="container px-0">
						<div className="row align-items-center">
							<div className="col d-flex">
								<div className="top-text">
									<small className="txt-black">Address</small>
									254 Street Avenue, LA US
            					</div>
								<div className="top-text">
									<small className="txt-black">Emaii Us</small>
									<a href="#">contacto@reykora.com</a>
								</div>
								<div className="top-text">
									<small className="txt-black">Phone Number</small>
									+88 (0) 202 0000 001
            					</div>
							</div>
							<div className="col-md-auto">
								<div className="dropdown d-inline-flex lang-toggle">
									<a href="#" className="dropdown-toggle btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
										<img src="images/us.svg" alt="" className="dropdown-item-icon" />
										<span className="d-inline-block d-lg-none">US</span>
										<span className="d-none d-lg-inline-block">United States</span> <i className="icofont-rounded-down"></i>
									</a>
									<div className="dropdown-menu dropdownhover-bottom dropdown-menu-right" role="menu">
										<a className="dropdown-item active" href="#">English</a>
										<a className="dropdown-item" href="#">Deutsch</a>
										<a className="dropdown-item" href="#">Español&lrm;</a>
									</div>
								</div>
								<div className="d-inline-flex request-btn ml-2">
									<a className="btn-theme icon-left bg-orange no-shadow d-none d-lg-inline-block align-self-center" href="#" role="button" data-toggle="modal" data-target="#request_popup"><i className="icofont-page"></i> Request Quote</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<nav className="navbar navbar-expand-lg bg-transparent">
					<div className="container text-nowrap bdr-nav px-0">
						<div className="d-flex mr-auto">
							<a className="navbar-brand rounded-bottom light-bg" href="home-one">
								<img src="images/logo_white.png" alt="" />
							</a>
						</div>
						<span className="order-lg-last d-inline-flex request-btn">
							<a className="nav-link mr-2 ml-auto" href="#" id="search_home"><i className="icofont-search"></i></a>
						</span>
						<button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarCollapse" data-hover="dropdown" data-animations="slideInUp slideInUp slideInUp slideInUp">
							<ul className="navbar-nav ml-auto">
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle-mob" href="home-one" id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Home <i className="icofont-rounded-down"></i></a>
									<ul className="dropdown-menu" aria-labelledby="dropdown03">
										<li><a className="dropdown-item" href="home-one">Home Layout 1</a></li>
										<li><a className="dropdown-item" href="home-two">Home Layout 2</a></li>
										<li><a className="dropdown-item" href="home-three">Home Layout 3</a></li>
										<li><a className="dropdown-item" href="home-four">Home Layout 4</a></li>
										<li><a className="dropdown-item" href="home-five">Home Layout 5</a></li>
										<li><a className="dropdown-item" href="home-six">Home Layout 6</a></li>
										<li className="dropdown">
											<a className="dropdown-toggle-mob dropdown-item" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Third Level Menu <i className="icofont-rounded-right float-right"></i></a>
											<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
												<li><a href="#" className="dropdown-item">Action</a></li>
												<li><a href="#" className="dropdown-item">Another action</a></li>
												<li><a href="#" className="dropdown-item">Something else here</a></li>
											</ul>
										</li>
									</ul>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="about">About Us</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle-mob" href="home-one" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages <i className="icofont-rounded-down"></i></a>
									<ul className="dropdown-menu">
										<li><a className="dropdown-item" href="shortcodes">Shortcode Elements</a></li>
										<li><a className="dropdown-item" href="typography">Typography</a></li>
										<li><a className="dropdown-item" href="requestquote">Request Quote</a></li>
										<li><a className="dropdown-item" href="pricing">Our Pricing</a></li>
										<li><a className="dropdown-item" href="notfound">404 Page</a></li>
									</ul>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="services">Services</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle-mob" href="home-one" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Projects <i className="icofont-rounded-down"></i></a>
									<ul className="dropdown-menu">
										<li><a className="dropdown-item" href="projectgrid">Projects Grid</a></li>
										<li><a className="dropdown-item" href="projectmasonary">Projects Masonary</a></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle-mob" href="home-one" id="blog-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Blog <i className="icofont-rounded-down"></i></a>

									<ul className="dropdown-menu" aria-labelledby="blog-menu">
										<li><a className="dropdown-item" href="bloglist">Blog List</a></li>
										<li><a className="dropdown-item" href="blogstandard">Blog Standard</a></li>
										<li><a className="dropdown-item" href="bloggrid">Blog Grid</a></li>
										<li><a className="dropdown-item" href="blogsingle">Blog Single</a></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle-mob" href="home-one" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Contact <i className="icofont-rounded-down"></i></a>
									<ul className="dropdown-menu">
										<li><a className="dropdown-item" href="contact">Contact Us</a></li>
										<li><a className="dropdown-item" href="contactusoption">Contact Us Option</a></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		</>
	);
};

export default Header;
